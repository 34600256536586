<template>
  <div class="LoginPanel">
    <div class="LoginPanelMain">
      <div class="LoginForm">
        <div class="LoginTitle">用户登录</div>

        <el-input
          class="row"
          size="medium"
          type="tenantId"
          v-model="tenantId"
          placeholder="请输入公司代码"
        ></el-input>
        <el-input
          class="row"
          size="medium"
          v-model="userName"
          placeholder="请输入用户名"
          autofocus
        ></el-input>
        <el-input
          class="row"
          size="medium"
          type="password"
          v-model="passWord"
          placeholder="请输入密码"
          show-password
        ></el-input>
        <div class="row row1">
          <!-- <span
            class="cursor-pointer"
            @click="
              onClickLogin(
                testAccess.name,
                testAccess.passWord,
                testAccess.tenantId
              )
            "
          >
            <Iconfont
              class="alarm-tip"
              :size="14"
              name="icon-tiyanzhanghao"
              :mRight="7"
            ></Iconfont>
            <span>体验账号</span>
          </span> -->
          <span></span>
          <span>
            <el-checkbox v-model="checked" style="color: #ffffff"
              >记住账号</el-checkbox
            >
          </span>
        </div>

        <LoginButton
          class="LoginButton"
          ref="loginBtn"
          @moveDone="onClickLogin(userName, passWord, tenantId)"
        ></LoginButton>
      </div>
    </div>

    <PanelBottom></PanelBottom>
    <Secondary v-model="isSecondVerify" />
  </div>
</template>

<script>
import PanelBottom from "./LoginPanelBottom.vue";
import LoginButton from "./LoginButton.vue";
import Secondary from "./Secondary.vue";
import { debounce, isObject } from "lodash";
import { encrypt, decrypt } from "@/utils/aes-ecb";
import { hasPerms } from "@/utils/auth";
import { appConfig } from "@/config/appConfig";
import Notification from "@/components/Notification";
import { amapGetIp } from "@/api/weather";
import { parseTime } from "@/utils/formatDate";

import {
  setLocalStorage,
  getLocalStorage,
  rmLocalStorage,
} from "@/utils/cache";
export default {
  name: "LoginPanel",
  components: {
    PanelBottom,
    LoginButton,
    Secondary
  },
  data() {
    return {
      // 登录用户名
      userName: "",
      // 登录密码
      passWord: "",
      // 公司代码
      tenantId: "",
      checked: false, //记住账号
      // 登录时的loading状态
      loginLoading: false,
      isSecondVerify: false, //
    };
  },
  computed: {
    testAccess() {
      return {
        name: appConfig.testAccount,
        passWord: appConfig.testPwd,
        tenantId: appConfig.tenantId,
      };
    },
  },
  created() {
    this.login = debounce(this.login, 1000, { leading: true }).bind(this);
    this.resetLoginBtn = debounce(this.resetLoginBtn, 1500).bind(this);
    this.onClickLogin = debounce(this.onClickLogin, 1000, {
      leading: true,
    }).bind(this);
  },
  mounted() {
    let accessInfo = getLocalStorage("accessInfo");

    if (accessInfo) {
      const data = JSON.parse(accessInfo);
      this.checked = data.remember;
      this.userName = data.userName;
      this.tenantId = data.tenantId;
      this.passWord = data.passWord;
    }
  },
  methods: {
    resetLoginBtn() {
      const { loginBtn } = this.$refs;
      loginBtn && loginBtn.reset();
    },
    onClickLogin(userName, password, tenantId) {
      if (!userName) {
        this.resetLoginBtn();
        return this.$message.warning("请输入用户名");
      }
      if (!password) {
        this.resetLoginBtn();
        return this.$message.warning("请输入密码");
      }
      if (!tenantId) {
        this.resetLoginBtn();
        return this.$message.warning("请输入公司代码");
      }

      const accessInfo = {
        remember: this.checked,
        userName: this.userName,
        passWord: this.passWord,
        tenantId: this.tenantId,
      };
      if (this.checked)
        setLocalStorage("accessInfo", JSON.stringify(accessInfo));
      this.login(userName, password, tenantId);
    },
    /**
     * userName: 登录名
     * password: 登录密码
     * tenantId: 公司代码
     * verifyCode: 验证码
     * redirect: 目标路由地址
     */
    async login(userName, password, tenantId, redirect) {
      try {
        this.loginLoading = true;
        const params = {
          userName,
          // password: encrypt(password),
          password: password,
          tenantId,
          code: "bysk2020",
          codeid: this.codeUUID,
        };
        const result = await this.$store.dispatch("login", params);
        // const result = await this.$store.dispatch("login", params);
        console.log(result);
        // 登录成功
        if (
          result?.success === true &&
          isObject(result.data?.platformLoginInfo) && !result.data?.secondVerify
        ) {
          this.$umaApi.umaLogin({
            login_user: userName,
            login_status: "登录成功",
          });
          if (result.data.extend && result.data.extend.lastServiceIP) {
            amapGetIp({
              key: "60f85fa7d1c353b0a2ffb92083e365f0",
              ip: result.data.extend.lastServiceIP,
            }).then((res) => {
              let lastLoginName = "";
              let lastLoginTime = "";
              if (res.info == "OK") {
                lastLoginName =
                  res.province + (res.city.length ? "" : res.city);
                lastLoginTime = parseTime(
                  result.data.extend.lastLoginTime,
                  "{yy}-{mm}-{dd} {hh}:{ii}:{ss}"
                );
              }
              console.log(lastLoginName, lastLoginTime);
              Notification({
                title: "系统通知",
                dangerouslyUseHTMLString: true,
                titleStyle: { color: "#008AFF" },
                iconStyle: { color: "#008AFF" },
                position: "bottom-right",
                message: `<div>上次登录时间：${lastLoginTime}</div><div>上次登录地点：${lastLoginName}</div><div>上次登录IP：${result.data.extend.lastServiceIP}</div>`,
              });
            });
          }
          // 判断是否为充值账户
          const { platformLoginInfo: obj } = result.data;
          if (obj.userId === 3147) {
            return this.$router.replace("/Recharge"); // 为充值账号的路由
          }

          this.$message({
            type: "success",
            message: "登录成功",
            duration: 2000,
            offset: 60,
          });
          // 中国石油判断
          if (
            this.$store.state.userRouter?.children?.find(
              (r) => r.code === "zgsy"
            ) &&
            this.$store.state.userInfo.name != "广东一立科技"
          ) {
            this.$router.replace("/BigScreenDisplay");
            return;
          }
          this.$router.replace("/");
          return;
        }else if(result?.success === true && result.data?.secondVerify) {
          this.isSecondVerify = true;
          return false;
        }
        this.$umaApi.umaLoginError({
          login_user: userName,
          login_status: result.msg || "登录失败",
        });
        return this.$message.warning(result.msg || "登录失败");
      } catch (err) {
        // console.log(err.response);
        // this.$umaApi.umaLoginError({
        //   login_user: userName,
        //   login_status: "登录异常"
        // });
        // this.$message.warning("登录异常", err);
      } finally {
        this.loginLoading = false;
        this.resetLoginBtn();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.LoginPanel {
  position: relative;
  background-color: #244195;
  width: 100%;
  // height: 100%;

  display: flex;
  flex-direction: column;

  color: #fff;
}
.LoginPanelMain {
  flex: 1;
  display: flex;
  // align-items: center;
  margin-top: 10%;
  justify-content: center;
}
.LoginForm {
  position: relative;
  width: 70%;
  height: 70%;
}

.LoginTitle {
  display: block;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 4px;
  margin-bottom: 40px;
  padding-top: 20px;
}

.row {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.row1 {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.LoginButton {
  margin-top: 40px;
  margin-bottom: 20px;
}
.cursor-pointer {
  cursor: pointer;
}

::v-deep .el-input__inner {
  border-radius: 0;
  background: none;
  color: #ffffff;
}

@media only screen and (max-width: 1536px) {
  .LoginForm {
    height: 80%;
  }
  .LoginTitle {
    margin-bottom: 40px;
  }

  .LoginButton {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 1366px) {
  .LoginForm {
    width: 80%;
    height: 70%;
  }
  .LoginTitle {
    margin-bottom: 40px;
  }

  .LoginButton {
    margin-top: 40px;
  }

  .row1 {
    font-size: 14px;

    ::v-deep.el-checkbox {
      .el-checkbox__label {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 1366px) and (max-height: 625px) {
  .LoginForm {
    height: 80%;
  }
  .LoginTitle {
    margin-bottom: 20px;
  }

  .LoginButton {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 960px) {
  .LoginTitle {
    font-size: 0.8em;
  }
  .row {
    margin-bottom: 20px;
    font-size: 0.7em;
  }
  .row1 {
    font-size: 0.6em;

    ::v-deep.el-checkbox {
      .el-checkbox__label {
        font-size: 0.6em;
      }
    }
  }

  ::v-deep.el-input {
    .el-input__inner {
      height: 26px;
    }
  }

  .loginFooter {
    height: 28px;
  }
}
</style>
