<template>
  <el-container class="LoginWrapper">
    <el-header height="80px" class="LoginHeader">
      <div class="logo-box">
        <img class="LOGO" src="../../assets/images/logo.png" />
        <span>一立云数据平台</span>
      </div>
      <connectModal class="connect" />
    </el-header>
    <el-main class="LoginMain">
      <el-row>
        <div style="display:flex">
          <CarouselImage style="flex: 16"></CarouselImage>
          <div style="flex:2"></div>
          <LoginPanel style="flex: 6"></LoginPanel>
        </div>
        <!-- <el-col :span="16">
          <CarouselImage></CarouselImage>
        </el-col>
        <el-col :span="6" :offset="1">
          <LoginPanel></LoginPanel>
        </el-col> -->
      </el-row>
    </el-main>
    <el-footer height="40px" class="LoginFooter">
      <span class="mr7">广东一立工程机械科技有限公司</span>
      <img class="footerImg" src="../../assets/images/备案.png" />
      <a
        class="beianCls"
        type="primary"
        href="https://beian.miit.gov.cn"
        target="_blank"
        >粤ICP备2021165493号</a
      >
    </el-footer>
  </el-container>
</template>

<script>
import connectModal from "./connectModal.vue";
import CarouselImage from "./CarouselImage.vue";
import LoginPanel from "./LoginPanel.vue";

export default {
  name: "Login",
  components: {
    connectModal,
    CarouselImage,
    LoginPanel,
  },
  beforeMount() {
    // TODO: 清缓存, 弹框等
    this.$store.dispatch("logout");
  },
  methods: {},
  mounted() {
  }
};
</script>

<style lang="scss" scoped>
.LoginWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.LoginHeader {
  height: 80px;
  background: #010a1d;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo-box {
  display: flex;
  align-items: center;
  height: 100%;
  >img {
    height: 100%;
  }
  >span {
    color: #fff;
    font-size: 0.3rem;
    font-weight: bold;
    letter-spacing:2px;
    margin-left: 6px;
  }
}
.LoginFooter {
  height: 40px;
  display: inline-flex;
  font-size: 14px;
  color: #000000;
  align-items: center;
  justify-content: center;
}
.footerImg {
  display: inline-block;
  width: 20px;
  height: 20px;
}
.beianCls {
  color: #1857d7;
  text-decoration: none;
  display: inline-flex;
}
.LoginMain {
  position: relative;
  padding: 0;
  flex: 1;
  width: 100%;
  // height: calc(100% - 80px - 40px);
  background-image: url("../../assets/images/login/登录页背景.png");
  background-size: cover;
  background-position: center;

  display: flex;
  align-items: center;
  justify-content: center;
}

.el-row {
  // height: 70%;
  width: 90%;
}
.el-col {
  height: 100%;
}

.mr7 {
  margin-right: 7px;
}

@media only screen and (max-width: 1366px) {
  .LoginHeader {
    height: 40px !important;
    line-height: 40px;
  }
  .LOGO {
    height: 80%;
  }
  .LoginFooter {
    height: 20px !important;
    line-height: 20px;
    font-size: 1em;
  }
  .footerImg {
    width: 16px;
    height: 16px;
  }
}

@media only screen and (max-width: 960px) {
  .LoginHeader {
    height: 30px !important;
    line-height: 30px;
  }
  .LOGO {
    height: 80%;
  }
  .LoginFooter {
    height: 10px !important;
    line-height: 10px;
    font-size: 0.36em;
  }
}
</style>