<template>
  <div class="loginFooter">
    <el-popover placement="bottom" trigger="hover">
      <div class="content">
        <img src="../../assets/images/login/code.png" />
        <span>
          <Iconfont class="alarm-tip" :size="22" name="icon-weixin"></Iconfont>
          <label>微信扫一扫，使用小程序</label>
        </span>
      </div>
      <span class="popover" slot="reference">小程序入口</span>
    </el-popover>

    <span class="help" @click="goRoute">帮助</span>
  </div>
</template>

<script>
import * as Help from '@/router/modules/Help';
import * as Recharge from '@/router/modules/Recharge';

export default {
  name: 'LoginPanelBottom',

  methods: {
    // 页面跳转 
    goRoute() {

      // const nav = Recharge.Recharge;

      const nav = Help.Help;
      this.$router.push(nav.path);
    }

  }
}
</script>

<style lang="scss" scoped>
.loginFooter {
  width: 100%;
  height: 72px;
  cursor: pointer;
  border-top: 1px solid #e0e5ef;

  display: flex;
  align-items: center;

  font-size: 16px;
  color: #fff;
  padding: 0 25px;
}
.help {
  position: absolute;
  right: 20px;
}
.content {
  display: flex;
  flex-flow: column;
  align-items: center;
  & > img {
    width: 100px;
    height: 100px;
    margin: auto;
  }
  & > span {
    display: flex;
    margin: 5px 0;
  }
}
.alarm-tip {
  margin-right: 5px;
}

@media only screen and (max-width: 1366px) {
  .loginFooter {
    font-size: 1em;
  }
  .content {
    font-size: 1em;
  }
}

@media only screen and (max-width: 960px) {
}
</style>