<template @resize="handleWrapperResize">
  <ResizeObserver>
    <div class="LoginButton">
      <span class="z-1 select-none">{{dragLabel}}</span>

      <div ref="dragOuter" class="DragOuter">
        <div :style="dragedStyle" class="hadDragged"></div>

        <div class="DragBtn" @mousedown.stop="onMoveStart">
          <Iconfont name="icon-xiayibu" :size="20"></Iconfont>
        </div>
      </div>
    </div>
  </ResizeObserver>
</template>

<script>
import ResizeObserver from '@/components/ResizeObserver';
import { sleep } from '@/utils/sleep';

export default {
  name: 'LoginButton',
  components: {
    ResizeObserver,
  },
  data() {
    return {
      flexPtg: 0,
      wrapperSize: 0,
      isMoving: false,
      startX: 0,
    };
  },
  computed: {
    dragLabel() {
      const { flexPtg } = this;
      if (flexPtg === 1) return '拖动完成, 立即登录';
      return '拖动滑块登录';
    },
    dragedStyle() {
      const { flexPtg } = this;
      const styleObj = {};

      styleObj.flexBasis = `${flexPtg * 100}%`;

      return styleObj;
    },
  },
  beforeMount() {
    this.onMoveStart = this.onMoveStart.bind(this);
    this.onMovingEnd = this.onMovingEnd.bind(this);
    this.onMoving = this.onMoving.bind(this);
  },
  mounted() {
    this.handleWrapperResize();
  },


  methods: {
    handleWrapperResize() {
      const { dragOuter } = this.$refs;
      if (dragOuter) {
        this.wrapperSize = dragOuter.clientWidth;
      }
    },
    reset() {
      this.startX = 0;
      this.flexPtg = 0;
      this.isMoving = false;
      this.onMovingEnd();
      this.handleWrapperResize();
    },
    onMoveStart(e) {

      if (this.isMoving) return;

      this.isMoving = true;
      this.startX = e.pageX;
      this.flexPtg = 0;
      window.addEventListener('mousemove', this.onMoving);
      window.addEventListener('mouseup', this.onMovingEnd);
      window.addEventListener('contextmenu', this.onMovingEnd);
    },
    onMovingEnd(e) {

      if (this.isMoving) {
        this.flexPtg = 0;
      }

      this.isMoving = false;
      window.removeEventListener('mousemove', this.onMoving);
      window.removeEventListener('mouseup', this.onMovingEnd);
      window.removeEventListener('contextmenu', this.onMovingEnd);

      this.$emit('moveEnd', e);
    },
    onMoving(e) {

      if (!this.isMoving) return;

      const { startX, wrapperSize } = this;

      let offsetPtg = parseFloat(((e.pageX - startX) / wrapperSize).toFixed(6));

      offsetPtg = Math.max(offsetPtg, 0);
      offsetPtg = Math.min(offsetPtg, 1);

      this.flexPtg = offsetPtg;

      if ((wrapperSize * offsetPtg) >= wrapperSize - 50) {
        this.isMoving = false;
        this.onMovingEnd();
        this.$emit('moveDone');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.LoginButton {
  position: relative;
  height: 50px;
  background-color: #4561ac;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #f8f8f8;
  user-select: none;
}
.DragOuter {
  position: absolute;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
}
.hadDragged {
  flex-basis: 40%;
  background-color: #7ac23c;
  height: 100%;
}
.DragBtn {
  height: 100%;
  width: 50px;
  background-color: #e6edf8;
  border: 1px solid #e0e5ef;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.z-1 {
  z-index: 1;
}

.select-none {
  user-select: none;
}

@media only screen and (max-width: 1366px) {
  .LoginButton {
    height: 40px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 960px) {
  .LoginButton {
    height: 30px;
    font-size: 12px;
  }
}
</style>