import { render, staticRenderFns } from "./connectModal.vue?vue&type=template&id=4c3c5f01&scoped=true"
var script = {}
import style0 from "./connectModal.vue?vue&type=style&index=0&id=4c3c5f01&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c3c5f01",
  null
  
)

export default component.exports