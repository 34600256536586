<template>
  <el-dialog title="身份验证" custom-class="secondary" width="500px" :visible.sync="dialogVisible" v-loading="loginLoading">
    <div class="secondary-box">
      <div class="secondary-box-text">为了您的账户安全，请验证身份</div> 
      <el-input class="secondary-box-readonly" v-model="sbInput" readonly placeholder="请输入内容"></el-input>
      <div class="secondary-box-cell">
        <div style="flex: 1">
          <el-input class="secondary-box-cell-input" :class="{'secondary-box-cell-input2': errorText ? true : false}" v-model="loginData.phoneSmsCode" placeholder="请输入验证码" @input="e=> e ? errorText = '' : errorText = '请输入验证码'"></el-input>
        </div>
        <el-button class="secondary-box-cell-button" @click="handleGetCode" :disabled="countdownNum ? true : false">{{countdownNum ? `${countdownNum}秒后重新发送` : '获取验证码'}}</el-button>
      </div>
      <div v-if="errorText" style="color:#f56c6c;">{{ errorText }}</div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="SecondaryLogin">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { sendSMSCode, secondVerify } from "@/api/SystemData"
import Notification from "@/components/Notification";
import { amapGetIp } from "@/api/weather";
import { parseTime } from "@/utils/formatDate";
import { debounce, isObject } from "lodash";
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['secondaryData'])
  },
  data() {
    return {
      dialogVisible: false,
      sbInput: '使用手机21321312验证',
      secondaryCode: '',
      loginData: {
        phoneSmsKey: '',
        phoneSmsCode: '',
      },
      countdownNum: 0,
      loginLoading: false,
      errorText: '',
    }
  },
  model: {
    prop: "showDialog",
    event: "change",
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit("change", false);
        }
      },
    },
    showDialog: {
      handler(v) {
        console.log(v);
        if (v) {
          this.dialogVisible = true;
        } else {
          this.dialogVisible = false;
        }
      },
    },
    secondaryData: {
      handler(v) {
        this.sbInput = `使用手机${v.verifyPhone}验证`
      },
      deep: true,
    }
  },
  created() {
    if (localStorage.getItem('countdownTime') && localStorage.getItem('countdownTime') > new Date().getTime() - 60000) {
      this.handleCountdown(Math.ceil((60000 - (new Date().getTime() - localStorage.getItem('countdownTime'))) / 1000));
    }
  },
  methods: {
    async handleGetCode() {
      let {code, data} = await sendSMSCode({
        phone: this.secondaryData.verifyPhone
      });
      if (code == 200) {
        this.handleCountdown();
        this.loginData.phoneSmsKey = data;
      };
    },
    async SecondaryLogin() {
      if (!this.loginData.phoneSmsCode) {
        this.errorText = "请输入验证码";
        return false;
      }
      try {
        this.loginLoading = true;
        const params = {
        ...this.secondaryData,
        ...this.loginData,
        phoneNumber: this.secondaryData.verifyPhone
      };
        const result = await this.$store.dispatch("secondaryLogin", params);
        // const result = await this.$store.dispatch("login", params);
        console.log(result);
        // 登录成功
        if (
          result?.success === true &&
          isObject(result.data?.platformLoginInfo) && !result.data?.secondVerify
        ) {
          this.$umaApi.umaLogin({
            login_user: result.data?.account,
            login_status: "登录成功",
          });
          if (result.data.extend && result.data.extend.lastServiceIP) {
            amapGetIp({
              key: "60f85fa7d1c353b0a2ffb92083e365f0",
              ip: result.data.extend.lastServiceIP,
            }).then((res) => {
              let lastLoginName = "";
              let lastLoginTime = "";
              if (res.info == "OK") {
                lastLoginName =
                  res.province + (res.city.length ? "" : res.city);
                lastLoginTime = parseTime(
                  result.data.extend.lastLoginTime,
                  "{yy}-{mm}-{dd} {hh}:{ii}:{ss}"
                );
              }
              Notification({
                title: "系统通知",
                dangerouslyUseHTMLString: true,
                titleStyle: { color: "#008AFF" },
                iconStyle: { color: "#008AFF" },
                position: "bottom-right",
                message: `<div>上次登录时间：${lastLoginTime}</div><div>上次登录地点：${lastLoginName}</div><div>上次登录IP：${result.data.extend.lastServiceIP}</div>`,
              });
            });
          }
          // 判断是否为充值账户
          const { platformLoginInfo: obj } = result.data;
          if (obj.userId === 3147) {
            return this.$router.replace("/Recharge"); // 为充值账号的路由
          }

          this.$message({
            type: "success",
            message: "登录成功",
            duration: 2000,
            offset: 60,
          });
          // 中国石油判断
          if (
            this.$store.state.userRouter?.children?.find(
              (r) => r.code === "zgsy"
            ) &&
            this.$store.state.userInfo.name != "广东一立科技"
          ) {
            this.$router.replace("/BigScreenDisplay");
            return;
          }
          this.$router.replace("/");
          return;
        }else if(result?.success === true && result.data?.secondVerify) {
          this.isSecondVerify = true;
          return false;
        }
        this.$umaApi.umaLoginError({
          login_user: userName,
          login_status: result.msg || "登录失败",
        });
        return this.$message.warning(result.msg || "登录失败");
      } catch (err) {
        console.log(err);
        // console.log(err.response);
        // this.$umaApi.umaLoginError({
        //   login_user: userName,
        //   login_status: "登录异常"
        // });
        // this.$message.warning("登录异常", err);
      } finally {
        this.loginLoading = false;
      }
    },
    handleCountdown(num = 60) {
      clearInterval(this.timeObj);
      this.countdownNum = num;
      num == 60 ? localStorage.setItem('countdownTime', new Date().getTime()) : '';
      this.timeObj = setInterval(()=> {
        this.countdownNum -= 1;
        if (this.countdownNum <= 0) {
          clearInterval(this.timeObj);
        }
      }, 1000)
    }
  }
}
</script>

<style lang="scss">
.secondary {
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 16px;
    font-weight: bold;
    height: 60px;
    line-height: 60px;
    padding: 0 24px;
    background: #fff;
    .el-dialog__title {
      font-weight: bold;
      color: #333333;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #333;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
    padding-top: 0;
  }
  &-box {
    padding: 0 50px;
    &-text {
      font-size: 12px;
    }
    &-readonly {
      width: 100%;
      margin: 20px 0;
      > input {
        font-size: 16px;
        color: #333 !important;
        height: 40px !important;
        line-height: 40px !important;
        border-radius: 4px !important;
      }
    }
    &-cell {
      display: flex;
      align-items: center;
      &-input {
        flex: 1;
        > input {
          font-size: 16px;
          color: #333 !important;
          height: 40px !important;
          line-height: 40px !important;
          border-radius: 4px !important;
        }
      }
      &-input2 {
        > input {
              background: #fef0f0 !important;
              border-color: #fbc4c4 !important;
        }
      }
      &-button {
        width: 120px;
        margin-left: 20px;
      }
    }
  }
}
</style>