<template>
  <div class="carouselOuter">
    <el-carousel indicator-position="outside" height="44vh" @change="changeCarousel">
      <el-carousel-item v-for="item in imageList" :key="item.src">
        <img class="carousel-img" :src="item.src" />
      </el-carousel-item>
    </el-carousel>
    <div class="carousel-text">
      <div class="labelCls">{{currentImage.labelCh}}</div>
      <div class="labelCls">{{currentImage.labelEn}}</div>
    </div>
  </div>
</template>

<script>


const imageList = [
  {
    src: require('../../assets/images/login/item_1.png'),
    labelCh: '为客户创造价值，促进工程机械行业的发展',
    labelEn: 'Create value for customers and promote the development of the construction machinery industry'
  },
  {
    src: require('../../assets/images/login/item_2.png'),
    labelCh: '一款为工程机械行业定制研发的主动安全预警设备',
    labelEn: 'An active safety early warning device customized for the construction machinery industry',
  },
  {
    src: require('../../assets/images/login/new_item_3.jpg'),
    labelCh: '用我们的数据服务，让您的工作变得安全、高效！',
    labelEn: 'Use our data service to make your work safe and efficient!',
  },
  {
    src: require('../../assets/images/login/item_4.png'),
    labelCh: '数据的整理、汇总、统计、分析，从各个维度挖掘人、设备、环境的风险因子，让管理更科学！',
    labelEn: 'Data sorting, summary, statistics, and analysis, mining the risk factors of people, equipment, and the environment from all dimensions, making management more scientific!',
  }
];

export default {
  name: 'CarouselImage',
  data() {
    return {
      imageList,
      activeIndex: 0,
    };
  },
  computed: {
    currentImage() {
      const { activeIndex } = this;
      return imageList[activeIndex];
    }
  },
  methods: {
    //  幻灯片轮播时赋值text
    changeCarousel(active, val) {
      this.activeIndex = active;
    }
  }
}
</script>

<style lang="scss" scoped>
.carouselOuter {
  width: 100%;
  // height: 100%;
  background: rgba(255, 255, 255, 0.25);
  color: #8b98b8;
  font-size: 16px;
}

.carousel-text {
  font-size: 1.3em;
  font-weight: 500;
  color: #8b98b8;
  padding-left: 23px;
}
.labelCls {
  margin-top: 23px;
  margin-bottom: 10px;
  height: 2em;
  &:first-child {
    color: #ffffff;
    font-size: 0.34rem;
  }
  &:last-child {
    color: #8b98b8;
    font-size: 0.32rem;
  }
}
.carousel-img {
  width: 100%;
  height: 100%;
}

::v-deep.el-carousel {
  // width: 100%;
  // // height: 76%;
  // display: flex;
  // flex-direction: column;

  .el-carousel__container {
    flex: 1;
  }
  .el-carousel__indicators--outside {
    text-align: left;
    padding-left: 23px;
  }
}

@media only screen and (max-width: 1366px) {
  .carousel-text {
    font-size: 1.4em;
  }
  .labelCls {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 960px) {
  .carousel-text {
    font-size: 1em;
  }
  .labelCls {
    margin-top: 15px;
  }
}
</style>